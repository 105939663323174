var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { FILTERS_EQUAL, FILTERS_IN, ErrorHandlerMixin, EmptyImage, PROMOTIONAL_PLANNING_SCENARIO_BUILDER } from "@kraftheinz/common";
import { P as PromoUtils } from "./promotion.planning.js";
import { e as URL_POT_OPTIONS } from "./api-endpoints.js";
import { n as normalizeComponent } from "./index.js";
import { P as PO_TBL_COLUMNS, d as PO_TBL_CONFIG, c as cloneDeep_1, e as PO_COMPARISON_TBL_CONFIG, f as PO_COMPARISON_TBL_COLUMNS } from "./cloneDeep.js";
import { cloneDeep, omit } from "lodash";
import numeral from "numeral";
const FINANCIALS_COMPARISON_TABLE_ROWS = [
  { dataIndex: "units", title: "Units" },
  { dataIndex: "pricePerUnits", title: "Price/unit" },
  { dataIndex: "rsv", title: "RSV $" },
  { dataIndex: "lsv", title: "LSV $" },
  { dataIndex: "invSales", title: "Inv Sales $" },
  { dataIndex: "nsv", title: "NSV $" },
  { dataIndex: "netCMA", title: "Net CMA $" },
  { dataIndex: "netCMAPercent", title: "Net CMA %" },
  { dataIndex: "gm", title: "GM $" },
  { dataIndex: "gmPercent", title: "GM %" },
  { dataIndex: "promoROI", title: "Promo ROI %" }
];
const CREATE_PERMISSION = "create";
var render$6 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { attrs: { "gutter": 16, "type": "flex", "align": "middle" } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": "", "allow-clear": "", "rules": "required", "label": "Region", "placeholder": "Select Region", "reference": "promotion-planning.common.regions", "source": "code", "source-label": "description", "value": _vm.filterForm.Region }, on: { "change": function($event) {
      return _vm.onSelectFilter("Region", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": "", "allow-clear": "", "rules": "required|max_selected:5", "label": "Key Account", "placeholder": "Select Key Account", "mode": "multiple", "reference": "promotion-planning.common.customers", "source": "id", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["keyAccountCode", "keyAccount"]);
    }, "is-clear-when-params-change": true, "fetch-options-on-filter": true, "api-not-get-paging": true, "clear-data-on-destroy": false, "max-tag-count": 2, "advanced-filters": _vm.keyAccountFilters, "value": _vm.filterForm.CustomerId }, on: { "change": function($event) {
      return _vm.onSelectFilter("CustomerId", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 2 } }, [_c("select-input", { attrs: { "form-item": "", "allow-clear": "", "rules": "required", "label": "Year", "placeholder": "Year", "data-source": _vm.years, "source": "year", "value": _vm.filterForm.FinYear }, on: { "change": function($event) {
      return _vm.onSelectFilter("FinYear", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { attrs: { "form-item": "", "allow-clear": "", "rules": "required", "label": "Cluster", "placeholder": "Select Cluster", "reference": "promotion-planning.common.clusters", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "custom-query": "all=false", "value": _vm.filterForm.ClusterCode }, on: { "change": function($event) {
      return _vm.onSelectFilter("ClusterCode", $event);
    } } })], 1)], 1), _c("a-row", { attrs: { "gutter": 16, "type": "flex", "align": "middle" } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": "", "allow-clear": "", "rules": "required", "label": "Category", "placeholder": "Select Category", "reference": "promotion-planning.common.categories", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "custom-query": "all=false", "is-clear-when-params-change": true, "fetch-options-on-filter": true, "api-not-get-paging": true, "clear-data-on-destroy": false, "advanced-filters": _vm.categoryFilters, "value": _vm.filterForm.CategoryCode }, on: { "change": function($event) {
      return _vm.onSelectFilter("CategoryCode", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": "", "allow-clear": "", "rules": "required", "label": "Sub Category", "placeholder": "Select Sub Category", "reference": "promotion-planning.common.sub-categories", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "custom-query": "all=false", "only-custom-query": "", "is-clear-when-params-change": true, "fetch-options-on-filter": true, "api-not-get-paging": true, "clear-data-on-destroy": false, "advanced-filters": _vm.subCategoryFilters, "value": _vm.filterForm.SubCategoryCode }, on: { "change": function($event) {
      return _vm.onSelectFilter("SubCategoryCode", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "form-item": "", "allow-clear": "", "rules": "required|max_selected:10", "label": "PPG", "placeholder": "Select PPG", "mode": "multiple", "reference": "promotion-planning.common.products", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "queries": _vm.ppgQueries, "max-tag-count": 2, "is-clear-when-params-change": true, "fetch-options-on-filter": true, "api-not-get-paging": true, "clear-data-on-destroy": false, "advanced-filters": _vm.ppgFilters, "value": _vm.filterForm.PPGCode }, on: { "change": function($event) {
      return _vm.onSelectFilter("PPGCode", $event);
    } } })], 1), _c("a-col", [_c("a-space", { staticClass: "mb-1" }, [_c("a-button", { on: { "click": _vm.onClearFilters } }, [_vm._v("Clear Filters")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isFiltering }, on: { "click": function($event) {
      return handleSubmit(_vm.onApplyFilters);
    } } }, [_vm._v(" Apply Filters ")])], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$6 = [];
var ScenarioBuilderFilter_vue_vue_type_style_index_0_lang = "";
const __vue2_script$6 = {
  name: "ScenarioBuilderFilter",
  mixins: [PromoUtils],
  inject: ["crud", "years"],
  props: {
    isFiltering: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const defaultFilterForm = {
      CategoryCode: void 0,
      ClusterCode: void 0,
      CustomerId: [],
      FinYear: void 0,
      Region: void 0,
      SubCategoryCode: void 0
    };
    const multipleSelectFields = ["CustomerId", "PPGCode"];
    const stateNational = { AU: "17", NZ: "08" };
    return {
      stateNational,
      defaultFilterForm,
      filterForm: __spreadValues({}, defaultFilterForm),
      multipleSelectFields
    };
  },
  computed: {
    keyAccountFilters() {
      if (!this.filterForm.Region)
        return null;
      return [
        ["Region", { operator: FILTERS_EQUAL, value: this.filterForm.Region }]
      ];
    },
    categoryFilters() {
      if (!this.filterForm.ClusterCode)
        return null;
      return [
        [
          "Cluster",
          { operator: FILTERS_EQUAL, value: this.filterForm.ClusterCode }
        ]
      ];
    },
    subCategoryFilters() {
      return [
        [
          "ClusterCode",
          { operator: FILTERS_EQUAL, value: this.filterForm.ClusterCode }
        ],
        [
          "CategoryId",
          { operator: FILTERS_EQUAL, value: this.filterForm.CategoryCode }
        ]
      ];
    },
    ppgFilters() {
      if (!this.filterForm.CategoryCode || !this.filterForm.CustomerId || !this.filterForm.Region || !this.filterForm.SubCategoryCode)
        return [["SubCategory"]];
      return [
        ...this.keyAccountFilters,
        ...this.categoryFilters,
        [
          "CustomerId",
          {
            operator: FILTERS_IN,
            value: this.filterForm.CustomerId
          }
        ],
        [
          "State",
          {
            operator: FILTERS_IN,
            value: this.stateNational[this.filterForm.Region]
          }
        ],
        [
          "Category",
          { operator: FILTERS_EQUAL, value: this.filterForm.CategoryCode }
        ],
        [
          "SubCategory",
          {
            operator: FILTERS_IN,
            value: this.filterForm.SubCategoryCode
          }
        ]
      ];
    },
    ppgQueries() {
      return [
        ["all", false],
        ["distinct", true],
        ["finYear", this.filterForm.FinYear]
      ];
    }
  },
  methods: {
    onApplyFilters() {
      this.$emit("apply");
    },
    onClearFilters() {
      this.crud.clearFilters();
      this.crud.deleteFilter("IsInactive");
      this.filterForm = __spreadValues({}, this.defaultFilterForm);
      this.crud.clearList();
      this.$emit("clear");
    },
    onSelectFilter(key, value) {
      if (!value || value.length === 0) {
        this.crud.deleteFilter(key);
        this.filterForm[key] = void 0;
      } else {
        this.crud.setFilter(key, {
          operator: this.multipleSelectFields.includes(key) ? FILTERS_IN : FILTERS_EQUAL,
          value
        });
        this.filterForm[key] = value;
      }
    }
  }
};
const __cssModules$6 = {};
var __component__$6 = /* @__PURE__ */ normalizeComponent(__vue2_script$6, render$6, staticRenderFns$6, false, __vue2_injectStyles$6, null, null, null);
function __vue2_injectStyles$6(context) {
  for (let o in __cssModules$6) {
    this[o] = __cssModules$6[o];
  }
}
var ScenarioBuilderFilter = /* @__PURE__ */ function() {
  return __component__$6.exports;
}();
const ColorfulEditableTable = () => import("./ColorfulEditableTable.js");
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-row", { attrs: { "gutter": [16, 32], "type": "flex", "justify": "end" } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("select-input", { attrs: { "data-source": _vm.plans, "source": "planId", "source-label": "planName", "value": _vm.planId }, on: { "change": _vm.onPlanChange } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("a-spin", { attrs: { "spinning": _vm.isFetching } }, [_c("colorful-editable-table", { attrs: { "tbl-columns": _vm.baseScenarioTblColumns, "tbl-rows": _vm.baseScenarioTblConfig, "tbl-data": _vm.tblData, "po-id": _vm.poId, "plan-id": _vm.planId, "uneditable": true, "disable-header": true, "gap-x": 1 } })], 1)], 1)], 1);
};
var staticRenderFns$5 = [];
var BaseScenario_vue_vue_type_style_index_0_lang = "";
const __vue2_script$5 = {
  name: "BaseScenario",
  components: { ColorfulEditableTable },
  props: {
    tblData: {
      type: Array,
      default: null
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    plans: {
      type: Array,
      default: null
    },
    poId: {
      type: [String, Number],
      default: null
    },
    planId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      baseScenarioTblColumns: []
    };
  },
  created() {
    this.baseScenarioTblColumns = cloneDeep(PO_TBL_COLUMNS.reduce((cols, col) => {
      if (col.type !== "criteria-group")
        cols.push(col);
      return cols;
    }, []));
    this.baseScenarioTblConfig = PO_TBL_CONFIG.map((item) => {
      if (["scanBaseDeal", "scanTopUpDeal"].includes(item.dataIndex))
        item.numberFormat = "$0,0.00";
      return omit(item, "criteriaGroup");
    });
  },
  methods: {
    onPlanChange(value) {
      if (!value)
        return;
      this.$emit("change-plan", value);
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, null, null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var BaseScenario = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-tooltip", { attrs: { "visible": _vm.hasError, "overlay-class-name": "error-message", "get-popup-container": function(trigger) {
    return trigger.parentNode;
  }, "auto-adjust-overflow": false, "placement": "topLeft", "title": _vm.scenarioDetails.message } }, [_vm.dataSource ? _c("a-row", { attrs: { "gutter": [16, 0], "type": "flex", "justify": "end" } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("div", { staticClass: "d-flex justify-content-between align-items-start" }, [_c("text-input", { staticStyle: { "width": "200px" }, attrs: { "form-item": "", "rules": "required", "disabled": _vm.isFetching, "custom-messages": { required: "Scenario Name is required." }, "custom-name": _vm.planId + "-planName", "value": _vm.scenarioDetails.planName }, on: { "change": function($event) {
    return _vm.onChange($event, "planName");
  } } }), _c("a-space", { attrs: { "align": "center" } }, [_c("span", { staticClass: "mr-2" }, [_vm._v("Year")]), _c("select-input", { staticStyle: { "width": "150px" }, attrs: { "show-label": false, "disabled": _vm.isFetching, "data-source": _vm.years, "source": "year", "value": _vm.scenarioDetails.finYear }, on: { "change": function($event) {
    return _vm.onChange($event, "finYear");
  } } })], 1)], 1)]), _c("a-col", { attrs: { "span": 24 } }, [_c("a-spin", { attrs: { "spinning": _vm.isFetching } }, [_c("colorful-editable-table", { attrs: { "tbl-columns": _vm.tblCols, "tbl-rows": _vm.tblConfig, "tbl-data": _vm.scenarioDetails.promotionOptimisationTypes, "po-id": _vm.poId, "plan-id": _vm.planId, "tbl-name": _vm.poId + "comparison", "gap-x": 0, "gap-y": 1 }, on: { "change": _vm.onChange, "pasted": _vm.replaceData } })], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns$4 = [];
var ReviewScenario_vue_vue_type_style_index_0_lang = "";
const __vue2_script$4 = {
  name: "ReviewScenario",
  components: { ColorfulEditableTable },
  inject: ["crud", "fetchComparisonData"],
  props: {
    dataSource: {
      type: Object,
      default: void 0
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    plans: {
      type: Array,
      default: null
    },
    poId: {
      type: [String, Number],
      default: null
    },
    planId: {
      type: [String, Number],
      default: null
    },
    finYear: {
      type: [String, Number],
      default: null
    },
    years: {
      type: Array,
      default: null
    }
  },
  data() {
    const tblConfig = cloneDeep_1(PO_COMPARISON_TBL_CONFIG);
    const tblCols = cloneDeep_1(PO_COMPARISON_TBL_COLUMNS);
    return {
      tblConfig,
      tblCols
    };
  },
  computed: {
    scenarioDetails() {
      return __spreadValues({}, this.dataSource);
    },
    hasError() {
      return this.dataSource && "isSuccess" in this.dataSource ? !this.dataSource.isSuccess : false;
    }
  },
  mounted() {
    this.tblConfig = this.tblConfig.map((item) => {
      if (["scanBaseDeal", "scanTopUpDeal"].includes(item.dataIndex))
        item.numberFormat = "$0,0.00";
      return item;
    });
  },
  methods: {
    onChange(data, key) {
      this.scenarioDetails.isSuccess = true;
      if (key)
        this.scenarioDetails[key] = data;
      else if (!key && data.length)
        this.scenarioDetails.promotionOptimisationTypes = data;
      this.$emit("change", this.scenarioDetails);
      if (key === "finYear") {
        this.fetchComparisonData(this.poId);
      }
    },
    replaceData(pastedData) {
      const newPromotionOptimisationTypes = [
        ...this.scenarioDetails.promotionOptimisationTypes
      ];
      pastedData.forEach((item) => {
        const _a = item, { index: index2 } = _a, otherProps = __objRest(_a, ["index"]);
        const currentData = newPromotionOptimisationTypes[index2];
        const newData = __spreadValues(__spreadValues({}, currentData), otherProps);
        newPromotionOptimisationTypes[index2] = newData;
      });
      this.scenarioDetails.promotionOptimisationTypes = [
        ...newPromotionOptimisationTypes
      ];
      this.$emit("change", this.scenarioDetails);
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ReviewScenario = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("div", { staticClass: "d-flex flex-column align-items-center justify-content-between" }, [_c("span", { staticClass: "p-2 mb-4" }, [_vm._v("New Scenario")]), _c("a-space", { attrs: { "direction": "vertical" } }, [_c("text-input", { attrs: { "label": "LSV%", "suffix": "%", "disabled": _vm.isFiltering || _vm.isReviewing, "value": _vm.reviewForm.lsv, "parse-value": _vm.parse2Decimal }, on: { "blur": function($event) {
      return _vm.changeReviewForm("lsv", $event);
    } } }), _c("text-input", { attrs: { "label": "COGS%", "suffix": "%", "disabled": _vm.isFiltering || _vm.isReviewing, "value": _vm.reviewForm.cogs, "parse-value": _vm.parse2Decimal }, on: { "blur": function($event) {
      return _vm.changeReviewForm("cogs", $event);
    } } }), _c("text-input", { attrs: { "label": "Base Elasticity", "disabled": _vm.isFiltering || _vm.isReviewing, "value": _vm.reviewForm.baseElasticity, "parse-value": _vm.parse2Decimal }, on: { "blur": function($event) {
      return _vm.changeReviewForm("baseElasticity", $event);
    } } }), _c("text-input", { attrs: { "label": "Add. Pr. Elast. %", "suffix": "%", "disabled": _vm.isFiltering || _vm.isReviewing, "value": _vm.reviewForm.addPrElastPercent, "parse-value": _vm.parse2Decimal }, on: { "blur": function($event) {
      return _vm.changeReviewForm("addPrElastPercent", $event);
    } } }), _c("select-input", { attrs: { "label": "GM Maintain", "rules": "required", "disabled": _vm.isFiltering || _vm.isReviewing, "data-source": _vm.ynOptions, "source": "value", "source-label": "label", "value": _vm.reviewForm.isGMMaintainEnabled ? 1 : 0 }, on: { "change": function($event) {
      return _vm.changeReviewForm("isGMMaintainEnabled", $event === 1);
    } } }), _c("select-input", { attrs: { "label": "Round Price", "rules": "required", "disabled": _vm.isFiltering || _vm.isReviewing, "data-source": _vm.ynOptions, "source": "value", "source-label": "label", "value": _vm.reviewForm.isRoundingPriceEnabled ? 1 : 0 }, on: { "change": function($event) {
      return _vm.changeReviewForm("isRoundingPriceEnabled", $event === 1);
    } } })], 1), _c("a-button", { staticClass: "mt-2", attrs: { "type": "primary", "disabled": _vm.isFiltering, "loading": _vm.isReviewing }, on: { "click": function($event) {
      return handleSubmit(_vm.onReviewClick);
    } } }, [_vm._v(" Review ")])], 1)];
  } }]) });
};
var staticRenderFns$3 = [];
var FormReviewScenario_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$3 = {
  name: "FormReviewScenario",
  props: {
    reviewData: {
      type: [Function, Object],
      default: void 0
    },
    isFiltering: {
      type: Boolean,
      default: false
    },
    isReviewing: {
      type: Boolean,
      default: false
    },
    planId: {
      type: Number,
      default: null
    },
    poId: {
      type: Number,
      default: null
    }
  },
  data() {
    const ynOptions = [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" }
    ];
    const defaultReviewForm = {
      lsv: "0.00",
      cogs: "0.00",
      isGMMaintainEnabled: true,
      isRoundingPriceEnabled: true,
      baseElasticity: "0.00",
      addPrElastPercent: "0.00",
      basePlanId: this.planId,
      basePromotionOptimisationId: this.poId
    };
    const typeNumberKeys = [
      "lsv",
      "cogs",
      "baseElasticity",
      "addPrElastPercent"
    ];
    return {
      defaultReviewForm,
      ynOptions,
      reviewForm: __spreadValues({}, defaultReviewForm),
      typeNumberKeys
    };
  },
  watch: {
    isFiltering(newVal) {
      if (newVal)
        this.reviewForm = __spreadValues({}, this.defaultReviewForm);
    },
    planId(newVal) {
      this.reviewForm.basePlanId = newVal;
    },
    poId(newVal) {
      this.reviewForm.basePromotionOptimisationId = newVal;
    },
    reviewData(newVal) {
      this.reviewForm = __spreadValues(__spreadValues({}, this.reviewForm), newVal);
    }
  },
  mounted() {
    this.$emit("change", this.reviewForm);
  },
  methods: {
    changeReviewForm(key, value) {
      this.reviewForm[key] = value;
      this.$emit("change", this.reviewForm);
    },
    parse2Decimal(value) {
      return value && !isNaN(value) ? parseFloat(value).toFixed(2) : "0.00";
    },
    onReviewClick() {
      this.$emit("review", this.reviewForm);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "43e4d417", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var FormReviewScenario = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.tableData ? _c("div", { staticClass: "financials-comparison-table" }, [_c("div", { staticClass: "w-100 d-flex justify-content-end" }, [_vm.title ? _c("h4", [_vm._v(_vm._s(_vm.title))]) : _vm._e()]), _c("a-tooltip", { attrs: { "title": "Re-calculate" } }, [_vm.hasReload ? _c("a-button", { staticClass: "reload-button mb-2", attrs: { "shape": "circle", "icon": "reload", "loading": _vm.reloading }, on: { "click": _vm.onReload } }) : _vm._e()], 1), !_vm.tableData.message ? _c("table", [_vm._m(0), _vm._l(_vm.tableRows, function(row) {
    return _c("tr", { key: row.dataIndex }, [_c("td", [_vm._v(_vm._s(row.title))]), _c("td", [_vm._v(" " + _vm._s((_vm.currencyFields.includes(row.dataIndex) ? "$" : "") + _vm.formatDecimal(_vm.tableData.base[row.dataIndex], row.dataIndex) + (_vm.percentFields.includes(row.dataIndex) ? "%" : "")) + " ")]), _c("td", [_vm._v(" " + _vm._s((_vm.currencyFields.includes(row.dataIndex) ? "$" : "") + _vm.formatDecimal(_vm.tableData.new[row.dataIndex], row.dataIndex) + (_vm.percentFields.includes(row.dataIndex) ? "%" : "")) + " ")]), _c("td", [_vm._v(" " + _vm._s(_vm.formatDecimal(_vm.tableData.percent[row.dataIndex]) + (_vm.percentFields.includes(row.dataIndex) ? "pp" : "%")) + " ")])]);
  })], 2) : _c("span", { staticClass: "financials-comparison-table__noti" }, [_vm._v(" " + _vm._s(_vm.tableData.message) + " ")])], 1) : _vm._e();
};
var staticRenderFns$2 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("tr", { staticClass: "financials-comparison-table__header" }, [_c("td"), _c("td", [_vm._v("Base")]), _c("td", [_vm._v("New")]), _c("td", [_vm._v("%")])]);
}];
var FinancialsComparisonTable_vue_vue_type_style_index_0_lang = "";
const __vue2_script$2 = {
  name: "FinancialsComparisonTable",
  mixins: [ErrorHandlerMixin],
  inject: ["fetchComparisonData"],
  props: {
    title: {
      type: String,
      default: null
    },
    tableData: {
      type: Object,
      default: void 0
    },
    hasReload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const tableRows = FINANCIALS_COMPARISON_TABLE_ROWS;
    const percentFields = ["netCMAPercent", "gmPercent", "promoROI"];
    const currencyFields = ["pricePerUnits"];
    return {
      reloading: false,
      tableRows,
      percentFields,
      currencyFields
    };
  },
  methods: {
    formatDecimal(value, dataIndex) {
      if (!value || isNaN(value))
        return value;
      let formatType = "0,0.00";
      if (dataIndex && dataIndex !== "pricePerUnits" && !this.percentFields.includes(dataIndex))
        formatType = "0,0";
      return numeral(value).format(formatType);
    },
    async onReload() {
      this.reloading = true;
      try {
        await this.fetchComparisonData();
        this.reloading = false;
      } catch (err) {
        this.displayErrorNotification(err);
      }
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var FinancialsComparisonTable = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "scenario-builder list" }, [_vm._m(0), _c("div", { staticClass: "m-5" }, [_c("section", { staticClass: "list__filters py-4" }, [_c("scenario-builder-filter", { attrs: { "is-filtering": _vm.isFiltering }, on: { "apply": _vm.onApplyFilters, "clear": _vm.reset } })], 1), _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var invalid = ref.invalid;
    return [_vm.listScenarioBuilder.length && _vm.comparisonData ? _c("div", { staticClass: "list__content bg-white px-4 py-2" }, [_c("a-collapse", { attrs: { "bordered": false, "active-key": "financials" } }, [_c("a-collapse-panel", { key: "financials", attrs: { "header": "Financials" } }, [_c("a-spin", { attrs: { "spinning": _vm.isFetchingComparisonData } }, [_vm.comparisonData.promotionOptimisationIds ? _c("div", { staticClass: "horizontal-scroll pb-2" }, [_c("a-space", { staticClass: "flex-nowrap", attrs: { "size": 100 } }, [_c("financials-comparison-table", { attrs: { "title": "Total", "has-reload": false, "table-data": _vm.comparisonData.total } }), _vm._l(_vm.comparisonData.keyAccounts, function(data) {
      return _c("financials-comparison-table", { key: data.keyAccount, attrs: { "has-reload": false, "title": data.keyAccount, "table-data": data } });
    })], 2)], 1) : _vm._e()])], 1)], 1)], 1) : _vm._e(), _vm.listScenarioBuilder.length ? _c("section", { staticClass: "list__content bg-white p-4" }, [_c("div", [_c("div", { staticClass: "w-100 d-flex justify-content-center" }, [_c("a-space", { attrs: { "size": "large" } }, [_c("a-button", { on: { "click": _vm.onApplyAll } }, [_vm._v("Apply to All")]), _c("a-button", { attrs: { "type": "primary", "loading": !!_vm.reviewingTables.length }, on: { "click": _vm.onReviewAll } }, [_vm._v(" Review All ")])], 1)], 1), _c("a-collapse", { attrs: { "bordered": false, "active-key": [_vm.subCategory.subCategoryCode] } }, [_c("a-collapse-panel", { key: _vm.subCategory.subCategoryCode, attrs: { "header": _vm.subCategory.subCategory } }, [_c("a-collapse", { attrs: { "bordered": false, "active-key": _vm.listScenarioBuilder[0].ppGs.map(function(ppg) {
      return ppg.ppgCode;
    }) } }, _vm._l(_vm.listScenarioBuilder[0].ppGs, function(ppg) {
      return _c("a-collapse-panel", { key: ppg.ppgCode, attrs: { "header": ppg.ppgCode + " - " + ppg.ppg } }, [_c("a-collapse", { attrs: { "bordered": false, "active-key": ppg.keyAccounts.map(function(keyAccount) {
        return keyAccount.keyAccountCode;
      }) } }, _vm._l(ppg.keyAccounts, function(keyAccount) {
        return _c("a-collapse-panel", { key: keyAccount.keyAccountCode, attrs: { "header": keyAccount.keyAccountCode + " - " + keyAccount.keyAccount, "set": _vm.plan = keyAccount.plan } }, [_c("a-space", { staticClass: "d-flex scenario-builder__wrapper horizontal-scroll" }, [_c("a-row", { staticClass: "scenario-table", attrs: { "type": "flex", "gutter": 16 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("base-scenario", { attrs: { "is-fetching": _vm.fetchingTables.includes(keyAccount.keyAccountCode), "tbl-data": _vm.plan.promotionOptimisationTypes, "po-id": _vm.plan.basePromotionOptimisationId, "plans": keyAccount.plans, "plan-id": _vm.plan.basePlanId }, on: { "change-plan": function($event) {
          return _vm.onPlanChange(keyAccount, $event);
        } } })], 1), _c("a-col", { staticClass: "h-100", attrs: { "span": 2 } }, [_c("form-review-scenario", { attrs: { "plan-id": _vm.plan.basePlanId, "po-id": _vm.plan.basePromotionOptimisationId, "is-filtering": _vm.isFiltering, "is-reviewing": _vm.reviewingTables.includes(_vm.plan.basePromotionOptimisationId), "review-data": _vm.getDataFromList(_vm.reviewForms, _vm.plan.basePlanId, _vm.plan.basePromotionOptimisationId) }, on: { "change": _vm.onReviewFormChange, "review": function($event) {
          return _vm.onReviewScenario(keyAccount.plan.basePromotionOptimisationId, $event);
        } } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("review-scenario", { attrs: { "is-fetching": _vm.reviewingTables.includes(_vm.plan.basePromotionOptimisationId), "data-source": _vm.getDataFromList(_vm.reviewScenarios, keyAccount.plan.basePlanId, keyAccount.plan.basePromotionOptimisationId), "po-id": _vm.plan.basePromotionOptimisationId, "plan-id": _vm.plan.basePlanId, "fin-year": _vm.finYear, "years": _vm.years }, on: { "change": _vm.onReviewScenarioChange } })], 1), _vm.comparisonData && _vm.comparisonData.plans.some(function(item) {
          return item.basePlanId === _vm.plan.basePlanId;
        }) ? _c("a-col", [_c("a-row", { staticClass: "h-100", attrs: { "type": "flex", "align": "middle" } }, [_c("a-col", [_c("a-spin", { attrs: { "spinning": _vm.reviewingTables.includes(_vm.plan.basePromotionOptimisationId) || _vm.fetchingComparisonTables.includes(_vm.plan.basePromotionOptimisationId) } }, [_c("financials-comparison-table", { staticClass: "ml-5", attrs: { "table-data": _vm.getDataFromList(_vm.comparisonData.plans, _vm.plan.basePlanId, _vm.plan.basePromotionOptimisationId) } })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1);
      }), 1)], 1);
    }), 1)], 1)], 1)], 1)]) : _c("a-empty", { attrs: { "image": _vm.EmptyImage.PRESENTED_IMAGE_SIMPLE } }), _vm.can(_vm.permissions.create) ? _c("section", { staticClass: "list__footer d-flex justify-content-end px-5 py-3" }, [_c("a-button", { attrs: { "disabled": !_vm.reviewScenarios.length || invalid, "type": "primary", "loading": _vm.isCreating }, on: { "click": _vm.onCreate } }, [_vm._v(" Create ")])], 1) : _vm._e()];
  } }]) })], 1)]);
};
var staticRenderFns$1 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list__title pt-2 pb-4" }, [_c("h2", { staticClass: "mb-0" }, [_vm._v("Scenario Builder")])]);
}];
var ScenarioBuilder_vue_vue_type_style_index_0_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ScenarioBuilder",
  mixins: [PromoUtils, ErrorHandlerMixin],
  components: {
    BaseScenario,
    ScenarioBuilderFilter,
    FormReviewScenario,
    ReviewScenario,
    FinancialsComparisonTable
  },
  inject: ["crud", "apiUrl", "can"],
  provide() {
    return {
      optionsProps: {
        crud: this.crud
      },
      parametersProps: {},
      selectedScenarioIds: [],
      years: this.years,
      fetchComparisonData: this.fetchComparisonData
    };
  },
  data() {
    const currentYear = this.$moment().year();
    const years = [
      { year: currentYear - 1 },
      { year: currentYear },
      { year: currentYear + 1 }
    ];
    return {
      comparisonData: void 0,
      comparisonUrl: "/promotion-planning/promo-optis/scenario-comparison",
      EmptyImage,
      fetchingTables: [],
      fetchingComparisonTables: [],
      isCreating: false,
      isFiltering: false,
      isFetchingComparisonData: false,
      reviewForms: [],
      reviewingTables: [],
      reviewScenarios: [],
      reviewUrl: "/promotion-planning/promo-optis/reviews",
      createUrl: "/promotion-planning/promo-optis/scenario-builder",
      urlOptions: URL_POT_OPTIONS,
      finYear: null,
      years,
      permissions: {
        create: CREATE_PERMISSION
      }
    };
  },
  computed: {
    listScenarioBuilder() {
      return this.crud.getList();
    },
    filteredFinYear() {
      return this.crud.getFilter("FinYear").value || null;
    },
    subCategory() {
      const { subCategoryCode, subCategory } = this.listScenarioBuilder[0];
      return { subCategoryCode, subCategory };
    }
  },
  async mounted() {
    this.crud.deleteFilter("IsInactive");
  },
  destroyed() {
    this.crud.clearList();
    this.crud.clearFilters();
    this.crud.deleteQueryStrings();
  },
  methods: {
    async fetchScenarioDetails(promotionId, scenarioId) {
      const apiEndpoint = apiUrl$1 + this.urlOptions + `?promotionId=${promotionId}&planId=${scenarioId}`;
      try {
        const { data } = await this.axios.get(apiEndpoint);
        return data;
      } catch (err) {
        this.displayErrorNotification(err);
      }
    },
    async getReviewData() {
      try {
        const { data } = await this.axios.post(this.apiUrl + this.reviewUrl, this.reviewForms);
        return data;
      } catch (err) {
        this.displayErrorNotification(err);
      }
    },
    async fetchComparisonData(poId) {
      try {
        this.isFetchingComparisonData = true;
        this.fetchingComparisonTables.push(poId);
        const { data } = await this.axios.post(this.apiUrl + this.comparisonUrl, { plans: this.reviewScenarios });
        if (!data)
          return;
        this.comparisonData = data;
      } catch (err) {
        this.displayErrorNotification(err);
      } finally {
        this.isFetchingComparisonData = false;
        this.fetchingComparisonTables = this.fetchingComparisonTables.filter((item) => item !== poId);
      }
    },
    onApplyAll() {
      const firstPpg = __spreadValues({}, this.reviewForms[0]);
      delete firstPpg.basePlanId;
      delete firstPpg.basePromotionOptimisationId;
      this.reviewForms = this.reviewForms.map((item) => {
        return __spreadValues(__spreadValues({}, item), firstPpg);
      });
    },
    async onApplyFilters() {
      try {
        this.finYear = this.crud.getFilter("FinYear").value || null;
        this.isFiltering = true;
        this.crud.deleteFilter("IsInactive");
        this.reset();
        this.crud.clearList();
        await this.crud.fetchList();
      } catch (err) {
        this.displayErrorNotification(err);
      } finally {
        this.isFiltering = false;
      }
    },
    reset() {
      this.reviewScenarios = [];
      this.reviewForms = [];
      this.comparisonData = void 0;
    },
    async onCreate() {
      try {
        this.isCreating = true;
        const { data } = await this.axios.post(`${this.apiUrl}${this.createUrl}`, this.reviewScenarios);
        this.reviewScenarios = data;
        let countSuccess = 0;
        let countFail = 0;
        data.map((item) => {
          if (item.isSuccess)
            countSuccess++;
          else if (!item.isSuccess)
            countFail++;
        });
        if (countSuccess > 0)
          this.$notification.success({
            message: `Successfully created ${countSuccess} scenario${countSuccess > 1 ? "s" : ""}`
          });
        if (countFail > 0)
          setTimeout(() => {
            this.$notification.error({
              message: `Failed to created ${countFail} scenario${countFail > 1 ? "s" : ""}`
            });
          }, 100);
      } catch (err) {
        this.displayErrorNotification(err);
      } finally {
        this.isCreating = false;
      }
    },
    onReviewFormChange(formData) {
      const { basePlanId, basePromotionOptimisationId } = formData;
      const scenarioIndex = this.reviewForms.findIndex((item) => item.basePlanId == basePlanId && item.basePromotionOptimisationId == basePromotionOptimisationId);
      if (scenarioIndex < 0) {
        this.reviewForms = [...this.reviewForms, __spreadValues({}, formData)];
      } else
        this.reviewForms[scenarioIndex] = __spreadValues({}, formData);
    },
    onReviewScenarioChange(data) {
      const { basePlanId, basePromotionOptimisationId } = data;
      this.reviewScenarios = this.reviewScenarios.map((item) => {
        if (item.basePlanId === basePlanId && item.basePromotionOptimisationId === basePromotionOptimisationId)
          return __spreadValues({}, data);
        return item;
      });
    },
    async onPlanChange(keyAccount, planId) {
      this.fetchingTables.push(keyAccount.keyAccountCode);
      try {
        const promoId = keyAccount.plan.basePromotionOptimisationId;
        const baseScenarioData = await this.fetchScenarioDetails(promoId, planId);
        this.reviewForms.map((item) => {
          if (item.basePromotionOptimisationId === promoId)
            item.basePlanId = planId;
          return item;
        });
        this.reviewScenarios = this.reviewScenarios.filter((item) => item.basePlanId !== planId && item.basePromotionOptimisationId !== promoId);
        await this.fetchComparisonData();
        keyAccount.plan.basePlanId = planId;
        keyAccount.plan.promotionOptimisationTypes = baseScenarioData;
      } finally {
        this.fetchingTables = this.fetchingTables.filter((item) => item !== keyAccount.keyAccountCode);
      }
    },
    async onReviewAll() {
      this.reviewingTables = this.reviewForms.map((item) => item.basePromotionOptimisationId);
      this.reviewScenarios = await this.getReviewData();
      await new Promise((resolve) => {
        this.reviewScenarios.forEach((item) => {
          item.finYear = this.finYear;
          item.planName = item.defaultPlanName;
        });
        resolve();
      });
      await this.fetchComparisonData();
      this.reviewingTables = [];
    },
    async onReviewScenario(poId, value) {
      try {
        this.reviewingTables.push(poId);
        const { basePlanId, basePromotionOptimisationId } = value;
        this.onReviewFormChange(value);
        const newScenarios = await this.getReviewData();
        const newScenario = this.getDataFromList(newScenarios, basePlanId, basePromotionOptimisationId);
        this.reviewScenarios = this.mapScenario(__spreadProps(__spreadValues({}, newScenario), {
          finYear: this.finYear,
          planName: newScenario.defaultPlanName
        }), this.reviewScenarios);
        await this.fetchComparisonData();
      } finally {
        this.reviewingTables = this.reviewingTables.filter((item) => item !== poId);
      }
    },
    getDataFromList(list, planId, poId) {
      if (!list.length)
        return;
      return list.find((item) => item.basePlanId === planId && item.basePromotionOptimisationId === poId);
    },
    mapScenario(newScenario, listScenario) {
      const { basePlanId, basePromotionOptimisationId } = newScenario;
      const newScenarioIndex = listScenario.findIndex((item) => item.basePlanId === basePlanId && item.basePromotionOptimisationId === basePromotionOptimisationId);
      if (newScenarioIndex < 0)
        listScenario.push(newScenario);
      else
        listScenario[newScenarioIndex] = newScenario;
      return listScenario;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ScenarioBuilder = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "promotion-planning.common.regions", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.customers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.clusters", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.categories", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.products", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.sub-categories", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.promo-optis.scenario-builder", "api-url": _vm.apiUrl, "resource-id-name": "id", "page": _vm.page } }, [_c("scenario-builder")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { ScenarioBuilder },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_SCENARIO_BUILDER,
      itemsMenu: [
        {
          key: "scenario-builder",
          title: "Scenario Builder",
          path: "/promotion-planning/scenario-builder"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
